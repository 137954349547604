import * as React from "react";
import { Link } from "gatsby";
import Header from "../components/Nav";
import Footer from "../components/Footer";
import { Box, Text, Image } from "@chakra-ui/react";
import "../style/404.css";
import bg from "../images/bg.png";

// markup
const NotFoundPage = () => {
  return (
    <>
      <Header />
      <Box className="Content404" textAlign={"center"} pt={{base:"100px", md:"0"}} mb={{base:"100px", xl:"0"}}>
        <Box className="textContent" position={"absolute"} margin={"auto"} right={"0"} left={"0"} >
          <Text fontSize={{base:"100px", md:"300px"}} fontWeight={"bold"}>
            404
          </Text>
          <Text fontSize={{base: "50px", md:"100px"}} fontWeight={"bold"}>
            Page introuvable
          </Text>
          <Link to="/" color="#FAFAFA">Revenir à la page d'accueil</Link>
        </Box>
        <Box className="bg" width={"auto"} height={"60%"} >
          <Image src={bg} width={"auto"} height={"100%"} margin={"auto"} left={"0"} right={"0"} textAlign={"center"}/>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default NotFoundPage;
